import React from 'react';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/index.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village</title>
    </PageHead>
  );
}

function IndexPage() {
  return (
    <Page className={styles.Index}>
      <section className={styles.pageCenter}>
        <h2 className={styles.smallHeading}>Mailing List Signup</h2>
        <p className={styles.subtext}>
          <span>Stay connected for future events.</span>
        </p>
        <form
          className={styles.formBox}
          action="https://catalunvillage.us21.list-manage.com/subscribe/post?u=2bfdf4f0ef9651845f2c8c46b&amp;id=d4a9d35615&amp;f_id=0019b9e1f0"
          method="post"
          name="mc-embedded-subscribe-form"
          target="_self"
        >
          <label htmlFor="name-signup" aria-label="name">
            Name
          </label>
          <input
            className={styles.textInput}
            id="name-signup"
            type="text"
            name="FIRST_NAME"
            placeholder="Enter your name"
            required
          />
          <label htmlFor="email-signup" aria-label="email">
            Email
          </label>
          <input
            className={styles.textInput}
            id="email-signup"
            type="email"
            name="EMAIL"
            placeholder="Enter your email address"
            required
          />
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_2bfdf4f0ef9651845f2c8c46b_d4a9d35615" tabIndex={-1} value="" />
          </div>
          <button className={styles.submitButton} type="submit">
            Sign Up
          </button>
        </form>
      </section>
    </Page>
  );
}

export default IndexPage;
